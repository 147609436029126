/*

 */

(function(document) {
    'use strict';

    /*
     Global variables
     */

    var slideIndex = 0,
        sliding = false,
        windowW = $(window).width(),
        windowH = $(window).height(),
        responsiveMode = windowW > 1024 ? false : true,
        touchMode = Modernizr.touch ? true : false,
        transformType = null,
        bodyStyle = document.body.style;

    /*
     Browser quirks
     */

    var browserQuirks = function () {

        if (bodyStyle.OTransform !== undefined) {
            transformType = '-o-transform';
        }
        if (bodyStyle.MozTransform !== undefined) {
            transformType = '-moz-transform';
        }
        if (bodyStyle.webkitTransform !== undefined) {
            transformType = '-webkit-transform';
        }
        if (bodyStyle.msTransform !== undefined) {
            transformType = '-ms-transform';
        }
        if (bodyStyle.transform !== undefined) {
            transformType = 'transform';
        }
    };

    browserQuirks();

    /*
     Panel Scroller
     */

    var buildPanelScroller = function(element) {
        element.append('<div class="pn-scroll-wrapper"><div class="pn-scroll"></div></div>');
        element.find('.pn-scroll').css(transformType, 'translate3d(0, 0, 0)');
    };

    var movePanelScroller = function(element, orientation, callback) {
        var pos = orientation === 'rtl' ? 0 : '100%';

        element.find('.pn-scroll').css('left', orientation === 'rtl' ? '100%' : 0);

        element.find('.pn-scroll').stop('true', 'true').animate({
            left: pos
        }, 500, function() {
            // Animation complete.
            callback();
        });
    };

    $('.tile').each(function () {
        buildPanelScroller($(this));
    });

    /*
     Sections Transitions
     */

    var enterMain = function () {
        $('#main-content').find('.panel_box').css(transformType, 'translate3d(0, 0, 0)');
        $('#first-image').css('opacity', 1);
    };

    var enterApproach = function () {
        $('#approach-image').css('opacity', 1);
        $('#approach-image').css(transformType, 'translate3d(0, 0, 0)');

        $('#approach-content').css(transformType, 'translate3d(0, 0, 0)');
        $('#approach-content').find('.panel_box').css(transformType, 'translate3d(0, 0, 0)');

        setTimeout(function() {
            $('#approach-content').find('.panel_content').css('opacity', 1);
        }, 700);
    };

    var enterGallery = function () {
        $('.slider-img, .slider-content').css(transformType, 'translate3d(0, 0, 0)');
    };

    var enterTeam = function () {
        setTimeout(function(){
            $('#team-section' ).stop('true', 'true').animate({
                opacity: 1
            }, 500, function() {
                // Animation complete.
            });
        }, 600);


        $('#team-content').find('.panel_box').css(transformType, 'translate3d(0, 0, 0)');
        $('#team-image').css(transformType, 'translate3d(0, 0, 0)');

    };

    var enterService = function () {
        $('.section--service').css('opacity', 1);
    };

    var enterContact = function () {
        $( '#contact-section').stop('true', 'true').animate({
            opacity: 1
        }, 500, function() {
            // Animation complete.
        });
    };

    var leaveSection = function(nextSection, callback) {
        $('.current-index').removeClass('current-index');
        $('#' + nextSection + '-section').addClass('current-index');

        callback();
    };

    var navToDestiny = function (section, index) {
        $('body').fadeOut(7, function(){
            setTimeout(function(){
                $('body').fadeIn(800, function() {

                });
            }, 600);
        });

        $.fn.fullpage.silentMoveTo(index);

        if(section === 'main') {
            enterMain();
        } else if(section === 'approach') {
            enterApproach();
        } else if(section === 'gallery') {
            enterGallery();
        } else if(section === 'team') {
            enterTeam();
        } else if(section === 'service') {
            movePanelScroller($('.tile'), 'ltr', function (){});
        } else if(section === 'contact') {
            enterContact();
        }

        $('body').addClass('navigation');
    };

    var setActiveMenuItem = function(section) {
        $('.nav_item--active').removeClass('nav_item--active');
        $('[data-navigation="'+  section + '"]').addClass('nav_item--active');
    };

    $('.js-nav-item').on('click', function (e) {
        e.preventDefault();

        var $this = $(this),
            data  = $this.data('navigation');

        switch (data) {
            case 'main':
                navToDestiny('main', 1);
            break;
            case 'approach':
                navToDestiny('approach', 2);
            break;
            case 'gallery':
                navToDestiny('gallery', 3);
            break;
            case 'team':
                navToDestiny('team', 4);
            break;
            case 'service':
                navToDestiny('service', 5);
            break;
            case 'contact':
                navToDestiny('contact', 6);
            break;

            default:

            break;
        }

        setActiveMenuItem(data);
    });

    /*
     Slick Slider
     */

    // Don't remove this script!!!'

    // $('.slider-img').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    //     if(currentSlide < nextSlide) {
    //         $('.slider-content').slick('slickPrev');
    //     } else {
    //         $('.slider-content').slick('slickNext');
    //     }
    // });
    //
    // var sliderImg = $('.slider-img').slick({
    //     infinite: false,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     swipe: false,
    //     arrows: true,
    //     vertical: true,
    //     prevArrow: '<button class="btn btn--arrow prev"><i class="icon icon-arrow-right-7-01"></i></button>',
    //     nextArrow: '<button class="btn btn--arrow next"><i class="icon icon-arrow-right-7-01"></i></button>',
    //     responsive: [
    //         {
    //             breakpoint: 1025,
    //             settings: {
    //                 vertical: false
    //             }
    //         }
    //     ]
    // });
    //
    // var sliderContent = $('.slider-content').slick({
    //     infinite: false,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     swipe: false,
    //     arrows: false,
    //     vertical: true,
    //     responsive: [
    //         {
    //             breakpoint: 1025,
    //             settings: {
    //                 vertical: false
    //             }
    //         }
    //     ]
    // });
    //
    // $('.slider-content').slick('slickGoTo', 4);

    /*
     Resize methods
     */

    $(window).resize(function () {
        $('body').addClass('navigation');
        windowW = $(window).width();
        windowH = $(window).height();

        if(windowW > 1023) {
            responsiveMode = false;
        } else {
            responsiveMode = true;
        }

    });

    /*
     Full page logic
     */

    if(!touchMode && !responsiveMode) {
        $('.fullpage').fullpage({
            responsiveWidth: 1025,
            onLeave: function(index, nextIndex, direction) {
                $('body').removeClass('navigation');

                if(index == 1 && direction =='down') {
                    console.log('going from main to approach');

                    leaveSection('approach', function () {
                        $('#main-content').find('.panel_box').css(transformType, 'translate3d(-100%, 0, 0)');
                        $('#first-image').css('opacity', 0);

                        enterApproach();
                    });
                }

                else if(index === 2) {
                    if (direction === 'down') {
                        console.log('going from approach to gallery');

                        leaveSection('gallery', function () {
                            $('#approach-content').css(transformType, 'translate3d(0, -100vh, 0)');
                            $('#approach-image').css(transformType, 'translate3d(0, -100vh, 0)');

                            enterGallery();
                        });

                    } else if (direction === 'up') {
                        console.log('going from approach to main');

                        enterMain();

                        $('#approach-image').css('opacity', 0);
                        $('#approach-content').find('.panel_box').css(transformType, 'translate3d(100%, 0, 0)');
                    }
                }

                else if(index === 3) {
                    if(direction === 'up') {
                        console.log('going from gallery to approach');

                        leaveSection('approach', function () {

                            enterApproach();

                            $('.slider-content').css(transformType, 'translate3d(0, -100vh, 0)');
                            $('.slider-img').css(transformType, 'translate3d(0, 100vh, 0)');
                        });
                    } else {
                        console.log('going from gallery to team');

                        leaveSection('team', function () {

                            $('.slider-img').css(transformType, 'translate3d(0, -100vh, 0)');

                            $('.slider-content').css(transformType, 'translate3d(0, 100vh, 0)');

                            enterTeam();
                        });
                    }
                }

                // Team to gallery
                else if(index === 4 && direction === 'up') {
                    console.log('going from team to gallery');

                    leaveSection('gallery', function () {
                        enterGallery();

                        $('#team-image').css(transformType, 'translate3d(0, 100vh, 0)');
                        $('#team-content').find('.panel_box').css(transformType, 'translate3d(0, -100vh, 0)');
                        // $('#team-image').css('top', '100vh');
                        // $('#team-content').find('.panel_box').css('top', '-100vh');
                    });
                }

                // Team to services
                else if(index === 4 && direction === 'down') {
                    console.log('going from team to services');

                    leaveSection('service', function () {
                        $( ".section--team" ).stop('true', 'true').animate({
                        opacity: 0
                        }, 500, function() {
                            // Animation complete.
                            enterService();
                            movePanelScroller($('.tile'), 'ltr', function (){});
                        });
                    });
                }

                // Services to Team
                else if(index === 5 && direction === 'up') {
                    console.log('going from services to team');

                    leaveSection('team', function () {
                        enterTeam();

                        movePanelScroller($('.tile'), 'rtl', function () {
                            $('#service-section').css('opacity', 0);
                        });
                    });
                }

                // Services to contact
                else if(index === 5 && direction === 'down') {
                    console.log('going from services to contact');

                    leaveSection('contact', function () {
                        movePanelScroller($('.tile'), 'rtl', function () {
                            $('.current-index').removeClass('current-index');
                            $('#contact-section').addClass('current-index');
                        });

                        setTimeout(function(){
                            $('#contact-section').css('opacity', 1);
                            $( '#service-section').stop('true', 'true').animate({
                                opacity: 0
                            }, 500, function() {
                                // Animation complete.
                            });
                        }, 600);
                    });
                }

                // Contact to services
                else if(index === 6 && direction === 'up') {
                    console.log('going from contact to services');
                    $('#service-section').css('opacity', 1);

                    leaveSection('service', function () {
                        $('#contact-section').stop('true', 'true').animate({
                            opacity: 0
                        }, 500, function() {
                            // Animation complete.
                            $('#contact-section' ).css({
                                'z-index': 6
                            });
                            movePanelScroller($('.tile'), 'ltr', function (){});
                        });
                    });
                }
            },
            afterLoad (anchorLink, index) {
                if(index === 1) {
                    setActiveMenuItem('main');

                    setTimeout(function () {
                        $('.super-index').removeClass('super-index');
                        $('#main-section').addClass('super-index');

                    },600);

                } else if(index === 2) {
                    setActiveMenuItem('approach');

                    setTimeout(function () {
                        $('.super-index').removeClass('super-index');
                        $('#approach-section').addClass('super-index')

                    },600);

                } else if(index === 3) {
                    setActiveMenuItem('gallery');

                    setTimeout(function () {
                        $('.super-index').removeClass('super-index');
                        $('#gallery-section').addClass('super-index');

                    },600);

                } else if(index === 4) {
                    setActiveMenuItem('team');

                    setTimeout(function () {
                        $('.super-index').removeClass('super-index');
                        $('#team-section').addClass('super-index');

                    },600);

                } else if(index === 5) {
                    setActiveMenuItem('service');

                    setTimeout(function () {
                        $('.super-index').removeClass('super-index');
                        $('#service-section').addClass('super-index');

                    },600);

                } else if(index === 6) {
                    setActiveMenuItem('contact');

                    setTimeout(function () {
                        $('.super-index').removeClass('super-index');
                        $('#contact-section').addClass('super-index');

                    },600);
                }
            }
        });
    }

    $('#contact-form-outside-button').click(function(e) {
        e.preventDefault();
        $('#contact-form-outside-button').removeClass('bounce-animation--small--in');
        $('#contact-form-outside-button').addClass('bounce-animation--small--out');

        setTimeout(function(){
            $('#contact-form-outside-button').hide();

            $('#contact-form-content').show();
            $('#contact-form-content').addClass('bounce-animation--big--in');
        },500);

        setTimeout(function(){
            $('#contact-form-content').addClass('form-active');
        },1000);
    });

    $('#contact-form-button').click(function(e) {
        e.preventDefault();

        $('#contact-form-content').removeClass('bounce-animation--big--in');
        $('#contact-form-content').addClass('bounce-animation--big--out');

        setTimeout(function(){
            $('#contact-form-content').removeClass('form-active');
            $('#contact-form-content').hide();
            $('#contact-form-outside-button').show();
            $('#contact-form-outside-button').removeClass('bounce-animation--small--out form-active');
            $('#contact-form-outside-button').addClass('bounce-animation--small--in');
        },400);
    });

    setTimeout(function(){
        $('.loader').fadeOut();
    }, 1000);

    $('#contact-form').submit(function(e) {
        e.preventDefault();

        $.post('/contact', $( '#contact-form' ).serialize())
            .done(function(data) {
                $('.form-message').text(data.success).fadeIn();

                setTimeout(function () {
                    $('.form-message').fadeOut();
                }, 2000);
        });
    });

})(document);